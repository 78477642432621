/*eslint-disable*/
import React from "react";
import Link from "next/link";

import {
  isBrowser,
  isMobile
} from "react-device-detect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "/components/CustomButtons/Button.js";

import styles from "/styles/jss/bbhost/pages/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);

export default function SocialNav({item}) {
  const classes = useStyles();

  if(item.justIcon && !isMobile)
  {
    return (
      <ListItem className={classes.listItem}>
          <Link href={item.route} as={item.route}>
            <Button
              href={item.route}
              color={item.color}            
              className={classes.navLink}
              simple
              target="_blank"
            >
             <FontAwesomeIcon icon={item.icon} size="2x" />
            </Button>
          </Link>    
      </ListItem>
    );
  }

  return (
    <ListItem className={classes.listItem}>
        <Link href={item.route} as={item.route}>
          <Button
            href={item.route}
            color={item.color}            
            className={classes.navLink}
            simple
            target="_blank"
          >
           <FontAwesomeIcon icon={item.icon} size="lg" /> {item.text}
          </Button>
        </Link>    
    </ListItem>
  );
}
